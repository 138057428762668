import React from 'react'

export const DateWithUTCTooltip = ({ date }: { date: Date }) => (
  <span className="cursor-pointer relative group">
    {new Date(date).toLocaleString()}

    <span className="opacity-0 w-[15rem] absolute bg-black -top-[40%] left-0 p-sm rounded-half text-white shadow-sm transition group-hover:opacity-100">
      {new Date(date).toLocaleString('default', { timeZone: 'UTC' }) + ' in UTC'}
    </span>
  </span>
)
