import React, { FunctionComponent } from 'react'
import { CampaignSearch } from './CampaignSearch'
import { usePromotedCampaigns } from './PromotedCampaignsProvider'
import { PromotedCampaignList } from './PromotedCampaignsList'

export const PromotedCampaigns: FunctionComponent = () => {
  const { promoted, status, error, maxPromotedCampaigns, operatingState, setOperatingState } = usePromotedCampaigns()

  return (
    <div className="card pt-md mt-lg">
      <div className="tabs m-md">
        <button className={`tab ${operatingState === 'NSW' ? 'active' : ''}`} onClick={() => setOperatingState('NSW')}>
          NSW
        </button>
        <button className={`tab ${operatingState === 'VIC' ? 'active' : ''}`} onClick={() => setOperatingState('VIC')}>
          VIC
        </button>
      </div>

      <h2 id="promotedCampaignsTitle" className="inline-block mx-lg text-lg">
        Promoted campaigns
      </h2>

      {error && (
        <div className="pb-md pr-lg pl-lg">
          <div className="alert alert-danger">{error}</div>
        </div>
      )}

      {status !== 'pending' && (
        <>
          <CampaignSearch />

          {promoted.length === maxPromotedCampaigns && (
            <div className="pt-md pb-md pr-lg pl-lg mb-md">
              <div className="alert alert-danger">You cannot promote more than 10 campaigns.</div>
            </div>
          )}
        </>
      )}

      {status === 'pending' ? (
        <div className="pt-md pb-md pr-lg pl-lg flex justify-center">
          <div className="loadingSpinner" />
        </div>
      ) : promoted.length > 0 ? (
        <PromotedCampaignList />
      ) : (
        <div className="pt-md pb-md pr-lg pl-lg">No promoted campaigns yet.</div>
      )}
    </div>
  )
}
