import React, { FormEvent, FunctionComponent, useContext, useState } from 'react'
import { CharityReviewContext } from '../../../../../contexts'
import { FormField, EditableData } from '../../../../../common'
import { SectionAttachments } from '../attachments/SectionAttachments'
import { charityValueMap } from '../../../common/charityValueMap'
import { hasCharityEditPermission } from '../../../../../../services/permission.service'

type Props = {
  title: string
  formFields: FormField[]
  initialValues: { [key: string]: any }
  onUpdate: (data: { [key: string]: any }) => void
  attachments: CharityFileAttachmentType[]
  documentTypes: CharityFileAttachmentType['type'][]
  isUpdating: boolean
}

export const EditableApplicationSection: FunctionComponent<Props> = ({
  title,
  formFields,
  initialValues,
  onUpdate,
  attachments,
  documentTypes,
  isUpdating
}) => {
  const { charity, approvedSections, approveSection, withdrawSectionApproval, editingSection, setEditingSection } =
    useContext(CharityReviewContext)
  const [documentsHasBeenReviewed, setDocumentsHasBeenReviewed] = useState<boolean>(false)

  const isApproved = approvedSections.indexOf(title) !== -1
  const isEditMode = editingSection === title
  const approveDisabled = isApproved || (attachments.length > 0 && !documentsHasBeenReviewed)

  const editClicked = () => {
    setEditingSection(title)
  }

  const editCancelled = () => {
    setEditingSection('')
  }

  const onApprove = () => {
    approveSection(title)
  }

  const withdrawApproval = (e: FormEvent) => {
    e.preventDefault()
    withdrawSectionApproval(title)
  }

  return (
    <>
      <EditableData
        isUpdating={isUpdating}
        isEditMode={isEditMode}
        formFields={formFields}
        initialValues={initialValues}
        displayValueMap={charityValueMap}
        onEdit={editClicked}
        onUpdate={onUpdate}
        editCancelled={editCancelled}
      />

      {documentTypes && (
        <SectionAttachments
          onReviewed={() => setDocumentsHasBeenReviewed(true)}
          charityId={charity.id}
          documentTypes={documentTypes}
          attachments={attachments}
        />
      )}

      {!isEditMode && charity.status === 'NEW' && hasCharityEditPermission() && (
        <div className="flex justify-end">
          <button
            className="btn btn-primary-danger mr-lg"
            aria-label={`Undo approval of ${title} section`}
            onClick={withdrawApproval}
            disabled={!isApproved}
          >
            Undo approval
          </button>
          <button
            className="btn btn-primary-dark"
            aria-label={`Approve ${title} section`}
            onClick={e => {
              e.preventDefault()
              onApprove()
            }}
            disabled={approveDisabled}
          >
            Approve section
          </button>
        </div>
      )}
    </>
  )
}
